* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
}

body {
  width: 100%;
  min-height: 100vh;
}

/* DESKTOP-TABLET VIEW */
@media screen and (min-width: 458px) {
  .wrapper {
    display: grid;
    grid-gap: 0px;
    grid-template-areas: "header  header" "nav     main" "footer  footer";
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 200px 1fr;
    max-width: 100%;
    height: 100vh;
  }
}
/* MOBILE VIEW */
@media screen and (max-width: 458px) {
  .wrapper {
    display: grid;
    grid-gap: 0px;
    grid-template-areas: "header" "nav" "main" "footer";
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 200px 1fr;
    max-width: 458px;
    height: 100vh;
  }
}
header {
  background-color: steelblue;
  grid-area: header;
  padding: 15px 5px 10px 5px;
  height: 96px;
}

nav {
  background-color: lightgrey;
  grid-area: nav;
  padding: 15px 5px 10px 5px;
}

main {
  background-color: whitesmoke;
  grid-area: main;
  overflow: auto;
  padding: 15px 5px 10px 5px;
}

footer {
  background-color: steelblue;
  grid-area: footer;
  padding: 5px 5px 5px 5px;
  height: 40px;
}

